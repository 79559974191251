<!-- Right Sidebar -->
<div class="right-bar" (clickOutside)="hide()" [exclude]="'.right-bar-toggle'" [excludeBeforeClick]="true">
    <ngx-simplebar class="h-100">

        <div class="rightbar-title">
            <a href="javascript:void(0);" class="right-bar-toggle float-end" (click)="hide()">
                <i class="mdi mdi-close"></i>
            </a>
            <h4 class="font-16 m-0 text-white">Visualización</h4>
        </div>

        <div class="p-3">

            <!-- Color -->
            <h6 class="fw-medium font-14 mt-4 mb-2 pb-1">Modo</h6>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="layoutColor" value="light" id="light-check"
                    [(ngModel)]="layoutColor" (change)="changeColorScheme('light');changeLeftSidebarTheme('light')" />
                <label class="form-check-label" for="light-check">Claro</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="layoutColor" value="dark" id="dark-check"
                    [(ngModel)]="layoutColor" (change)="changeColorScheme('dark');changeLeftSidebarTheme('dark')" />
                <label class="form-check-label" for="dark-check">Oscuro</label>
            </div>


<!--            &lt;!&ndash; Width &ndash;&gt;-->
<!--            <h6 class="fw-medium font-14 mt-4 mb-2 pb-1">Width</h6>-->
<!--            <div class="form-check form-switch mb-1">-->
<!--                <input type="radio" class="form-check-input" name="layoutWidth" value="fluid" id="fluid-check"-->
<!--                    [(ngModel)]="layoutWidth" (change)="changeLayoutWidth('fluid')" />-->
<!--                <label class="form-check-label" for="fluid-check">Fluid</label>-->
<!--            </div>-->
<!--            <div class="form-check form-switch mb-1">-->
<!--                <input type="radio" class="form-check-input" name="layoutWidth" value="boxed" id="boxed-check"-->
<!--                    [(ngModel)]="layoutWidth" (change)="changeLayoutWidth('boxed')" />-->
<!--                <label class="form-check-label" for="boxed-check">Boxed</label>-->
<!--            </div>-->

<!--            &lt;!&ndash; Menu positions &ndash;&gt;-->
<!--            <h6 class="fw-medium font-14 mt-4 mb-2 pb-1">Menus (Leftsidebar and Topbar) Positon</h6>-->
<!--            <div class="form-check form-switch mb-1">-->
<!--                <input type="radio" class="form-check-input" name="leftbarPosition" value="fixed" id="fixed-check"-->
<!--                    [(ngModel)]="leftbarPosition" (change)="changeMenuPosition('fixed')" />-->
<!--                <label class="form-check-label" for="fixed-check">Fixed</label>-->
<!--            </div>-->

<!--            <div class="form-check form-switch mb-1">-->
<!--                <input type="radio" class="form-check-input" name="leftbarPosition" value="scrollable"-->
<!--                    id="scrollable-check" [(ngModel)]="leftbarPosition" (change)="changeMenuPosition('scrollable')" />-->
<!--                <label class="form-check-label" for="scrollable-check">Scrollable</label>-->
<!--            </div>-->

            <!-- Left Sidebar-->
            <ng-container *ngIf="layoutType!=='horizontal'">
<!--                <h6 class="fw-medium font-14 mt-4 mb-2 pb-1">Left Sidebar Color</h6>-->

<!--                <div class="form-check form-switch mb-1">-->
<!--                    <input type="radio" class="form-check-input" name="leftbarColor" value="light" id="light-check"-->
<!--                        [(ngModel)]="leftbarColor" (change)="changeLeftSidebarTheme('light')" />-->
<!--                    <label class="form-check-label" for="light-check">Light</label>-->
<!--                </div>-->

<!--                <div class="form-check form-switch mb-1">-->
<!--                    <input type="radio" class="form-check-input" name="leftbarColor" value="dark" id="dark-check"-->
<!--                        [(ngModel)]="leftbarColor" (change)="changeLeftSidebarTheme('dark')" />-->
<!--                    <label class="form-check-label" for="dark-check">Dark</label>-->
<!--                </div>-->

<!--                <div class="form-check form-switch mb-1">-->
<!--                    <input type="radio" class="form-check-input" name="leftbarColor" value="brand" id="brand-check"-->
<!--                        [(ngModel)]="leftbarColor" (change)="changeLeftSidebarTheme('brand')" />-->
<!--                    <label class="form-check-label" for="brand-check">Brand</label>-->
<!--                </div>-->

<!--                <div class="form-check form-switch mb-3">-->
<!--                    <input type="radio" class="form-check-input" name="leftbarColor" value="gradient"-->
<!--                        id="gradient-check" [(ngModel)]="leftbarColor" (change)="changeLeftSidebarTheme('gradient')" />-->
<!--                    <label class="form-check-label" for="gradient-check">Gradient</label>-->
<!--                </div>-->

                <!-- size -->
                <ng-container *ngIf="!disableLeftBarSize">
                    <h6 class="fw-medium font-14 mt-4 mb-2 pb-1">Menú lateral</h6>

                    <div class="form-check form-switch mb-1">
                        <input type="radio" class="form-check-input" name="leftbarSize" value="default"
                            id="default-size-check" [(ngModel)]="leftbarSize"
                            (change)="changeLeftSidebarSize('default')" />
                        <label class="form-check-label" for="default-size-check">Por defecto</label>
                    </div>

                    <div class="form-check form-switch mb-1">
                        <input type="radio" class="form-check-input" name="leftbarSize" value="condensed"
                            id="condensed-check" [(ngModel)]="leftbarSize"
                            (change)="changeLeftSidebarSize('condensed')" />
                        <label class="form-check-label" for="condensed-check">Minimizado</label>
                    </div>

<!--                    <div class="form-check form-switch mb-1">-->
<!--                        <input type="radio" class="form-check-input" name="leftbarSize" value="compact"-->
<!--                            id="compact-check" [(ngModel)]="leftbarSize" (change)="changeLeftSidebarSize('compact')" />-->
<!--                        <label class="form-check-label" for="compact-check">Compact <small>(Small-->
<!--                                size)</small></label>-->
<!--                    </div>-->
                </ng-container>
            </ng-container>

            <!-- User info -->
<!--            <ng-container *ngIf="layoutType!=='horizontal'">-->
<!--                <h6 class="fw-medium font-14 mt-4 mb-2 pb-1">Sidebar User Info</h6>-->

<!--                <div class="form-check form-switch mb-1">-->
<!--                    <input type="checkbox" class="form-check-input" name="showSidebarUserInfo" value="fixed"-->
<!--                        id="sidebaruser-check" [(ngModel)]="showSidebarUserInfo"-->
<!--                        (change)="toggleLeftSidebarUserInfo(showSidebarUserInfo)" />-->
<!--                    <label class="form-check-label" for="sidebaruser-check">Enable</label>-->
<!--                </div>-->
<!--            </ng-container>-->

            <!-- Topbar -->
<!--            <ng-container *ngIf="layoutColor!=='dark'">-->
<!--                <h6 class="fw-medium font-14 mt-4 mb-2 pb-1">Topbar</h6>-->

<!--                <div class="form-check form-switch mb-1">-->
<!--                    <input type="radio" class="form-check-input" name="topbarColor" value="dark" id="darktopbar-check"-->
<!--                        [(ngModel)]="topbarColor" (change)="changeTopbarColor('dark')" />-->
<!--                    <label class="form-check-label" for="darktopbar-check">Dark</label>-->
<!--                </div>-->

<!--                <div class="form-check form-switch mb-1">-->
<!--                    <input type="radio" class="form-check-input" name="topbarColor" value="light" id="lighttopbar-check"-->
<!--                        [(ngModel)]="topbarColor" (change)="changeTopbarColor('light')" />-->
<!--                    <label class="form-check-label" for="lighttopbar-check">Light</label>-->
<!--                </div>-->
<!--            </ng-container>-->

<!--            <div class="d-grid mt-4">-->
<!--                <button class="btn btn-primary" id="resetBtn" (click)="reset()">Restablecer</button>-->
<!--            </div>-->
        </div>

    </ngx-simplebar>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
