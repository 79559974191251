import { MenuItem } from '../models/menu.model';

const MENU_ITEMS_USER_ROLE: MenuItem[] = [
  { key: 'options', label: 'Operativa', isTitle: true },
    {
        key: 'welcome',
        label: 'Inicio',
        isTitle: false,
        icon: 'fe-home',
        url: '/welcome',
    },
    {
        key: 'reports',
        label: 'Reportes',
        isTitle: false,
        icon: 'fe-pie-chart',
        collapsed: true,
        children: [
            {
                key: 'planning',
                label: 'Planificación',
                url: '/planning-report',
                parentKey: 'reports'
            },
            {
                key: 'monthly',
                label: 'Mensual',
                url: '/monthly-report',
                parentKey: 'reports'
            },
            {
                key: 'payment',
                label: 'Pagos',
                url: '/payment-report',
                parentKey: 'reports'
            }
        ]
    },
    {
        key: 'consignments',
        label: 'Remesas',
        isTitle: false,
        icon: 'fe-clipboard',
        url: '/consignments',
    }
];

const MENU_ITEMS_ADMIN_ROLE: MenuItem[] = [
  { key: 'options', label: 'Operativa', isTitle: true },
    {
        key: 'welcome',
        label: 'Inicio',
        isTitle: false,
        icon: 'fe-home',
        url: '/welcome',
    },
    {
        key: 'reports',
        label: 'Reportes',
        isTitle: false,
        icon: 'fe-pie-chart',
        collapsed: true,
        children: [
            {
                key: 'planning',
                label: 'Planificación',
                url: '/planning-report',
                parentKey: 'reports'
            },
            {
                key: 'monthly',
                label: 'Mensual',
                url: '/monthly-report',
                parentKey: 'reports'
            },
            {
                key: 'payment',
                label: 'Pagos',
                url: '/payment-report',
                parentKey: 'reports'
            }
        ]
    },
    {
        key: 'consignments',
        label: 'Remesas',
        isTitle: false,
        icon: 'fe-clipboard',
        url: '/consignments',
    },
  { key: 'settings', label: 'Configuración', isTitle: true },
  {
    key: 'users',
    label: 'Usuarios',
    isTitle: false,
    icon: 'fe-users',
    url: '/users',
  }
];

export { MENU_ITEMS_ADMIN_ROLE, MENU_ITEMS_USER_ROLE };
