<footer class="footer">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-12 col-xs-12">
          &copy; {{today | date:'yyyy'}}. <a style="color: #222; font-weight: bold" href="https://gesthispania.com" target="_blank"><strong>Gestorías Asociadas Gesthispania</strong> </a>
      </div>
    </div>
  </div>
</footer>

<footer class="footer">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6 col-xs-12 left">
        &copy; {{today | date:'yyyy'}}. <a style="color: #222; font-weight: bold" href="https://gesthispania.com" target="_blank"><strong>Gestorías Asociadas Gesthispania</strong> </a>
      </div>
      <div class="col-md-6 col-xs-12 d-none right">
        <div class="btn-group-vertical mb-2">
          <a class="nav-link me-0" href="mailto:ticket.desarrollo@gesthispania.com">
            <i class="fe-headphones"></i>
            Contactar con soporte
          </a>
        </div>
      </div>
      <div class="col-md-6 col-xs-12 d-xs-none">
        <div class="text-md-end footer-links d-sm-block">
          <a class="nav-link me-0" href="mailto:ticket.desarrollo@gesthispania.com">
            <i class="fe-headphones"></i>
            Contactar con soporte
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
