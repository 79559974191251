<router-outlet></router-outlet>
<ng-template #staticBackdropModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modalLabel"><i class="mdi mdi-alarm"></i> Atención</h4>
  </div>
  <div class="modal-body">
    <p class="m-0">El sistema ha detectado que lleva un largo periodo de inactividad, ¿desea mantener la sesión iniciada?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="logOut()">Cerrar sesión</button>
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">Aceptar</button>
  </div>
</ng-template>
