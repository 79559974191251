<div class="account-pages my-5">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-4">
                <div class="text-center" *ngIf="hasLogo">
                    <div class="auth-logo mb-3">
                        <a [routerLink]="'/'" class="logo logo-dark ">
                            <span class="logo-lg">
                                <img src="../../../../assets/images/logos/logo-utilidades.svg" alt="Logo Gesthispania Tools" height="56">
                            </span>
                        </a>
                    </div>
                </div>
                <div class="card" [class]="cardClass">
                    <div class="card-body p-4">

                        <ng-content></ng-content>

                    </div> <!-- end card-body -->
                </div>

                <ng-content select="[footerLink]"></ng-content>

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->
