import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { loggedInUser } from '../helpers/utils';
import { User } from '../models/auth.models';
import { environment } from "../../../environments/environment";


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  user: any;

    constructor (private http: HttpClient) {
    }

    public currentUser(): User | null {
        if (!this.user) {
            this.user = loggedInUser();
        }
        return this.user;
    }

    login(username: string, password: string): Observable<User> {
        let body = new URLSearchParams();
        body.set('username', username);
        body.set('password', password);
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        const url = environment.URL_API + 'auth/jwt/login';
        return this.http
            .post(url, body.toString(), options)
            .pipe(map(user => {
                return user;
            }));
    }

    getToken() {
        // @ts-ignore
        return JSON.parse(sessionStorage.getItem('currentUser')).token;
    }

}

