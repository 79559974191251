
<!-- Topbar Start -->
<div class="navbar-custom">
  <div [class]="containerClass">
    <ul class="list-unstyled topnav-menu float-end mb-0">
        <li class="notification-list profile" ngbDropdown>
            <a class="nav-user me-0">
                <div class="ms-1">
                    <i class="fe-user"></i>
                    <span id="user-name"> {{loggedInUser?.name}} {{loggedInUser?.surname}}</span>
                </div>
            </a>
        </li>
      <li class="d-none d-lg-block" ngbDropdown placement="bottom-start">
      </li>
        <li class="dropdown notification-list" style="font-weight: 500;">
            <a class="nav-link me-0 user-log-out" [routerLink]="['auth/logout']">
                <i class="fe-lock"></i>
                Cerrar sesión
            </a>
        </li>
    </ul>

    <!-- LOGO -->
    <div class="logo-box">
      <a routerLink="/" class="logo logo-dark text-left">
                <span class="logo-sm">
                    <img src="../../../../assets/images/logos/logo-utilidades.svg" alt="Logo Gesthispania Tools" height="40">
                </span>
        <span class="logo-lg">
                    <img src="../../../../assets/images/logos/logo-utilidades.svg" alt="Logo Gesthispania Tools" height="40">
                </span>
      </a>
    </div>

    <ul class="list-unstyled topnav-menu topnav-menu-left mb-0">
      <li *ngIf="layoutType==='vertical'">
        <button class="button-menu-mobile disable-btn waves-effect open-left"
                (click)="toggleMobileMenu($event)">
          <i class="fe-menu"></i>
        </button>
      </li>

      <li *ngIf="layoutType==='horizontal'">
        <!-- Mobile menu toggle (Horizontal Layout)-->
        <a class="navbar-toggle nav-link horizontal-toggle" (click)="toggleMobileMenu($event)">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </li>

      <li *ngIf="layoutType==='vertical'">
        <h4 class="page-title-main">{{pageTitle}}</h4>
      </li>

    </ul>

    <div class="clearfix"></div>
  </div>
</div>
<!-- end Topbar -->

