import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/service/auth.service';
import { User } from '../../../core/models/auth.models';

@Component({
  selector: 'app-pages-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {

  loggedInUser: User | null = {};
  buttonMessage = '';

  constructor (
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.loggedInUser = this.authService.currentUser();
    if (this.loggedInUser !== null) {
      this.buttonMessage = 'Volver atrás';
    } else {
      this.buttonMessage = 'Volver a iniciar sesión';
    }
  }

}
