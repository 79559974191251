import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pages-error401',
  templateUrl: './error401.component.html',
  styleUrls: ['./error401.component.scss']
})
export class Error401Component {

  public errorMessage: string | undefined;

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        error?: string
      };
      if (typeof(state) !== 'undefined') {
        // @ts-ignore
        if (state.error.includes('Invalid credentials')) {
          this.errorMessage = 'La contraseña introducida no es correcta';
        } else {
          this.errorMessage = state.error;
        }
      } else {
        this.router.navigate(['']);
      }
    }
  }

}
