import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "./auth.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) { }

  login(username: string, password: string): Observable<any> {
    let body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    const url = environment.URL_API + 'auth/jwt/login';
    return this.http
        .post(url, body.toString(), options)
        .pipe(map(user => {
          return user;
        }));
  }

  logout(): void {
    sessionStorage.removeItem('currentUser');
    const url = environment.URL_API + 'auth/jwt/logout';
    this.http.post(url, {});
  }

  recoverPassword(email: string): Observable<any> {
    const url = environment.URL_API + 'auth/forgot-password';
    return this.http.post<any>(url, {email: email});
  }

  resetPassword(password: string, token: string): Observable<any> {
    const url = environment.URL_API + 'auth/reset-password';
    return this.http.post<any>(url, {password: password, token: token});
  }

  changeUserStatus(id: string, operation: string): Observable<any> {
    const url = environment.URL_API + 'user/' + operation + '/' + id;
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.put<any>(url, headers);
  }

  getUserData(): Observable<any> {
    const url = environment.URL_API + 'users/me';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.get<any>(url, {headers: headers});
  }

  getUsers(): Observable<any> {
    const url = environment.URL_API + 'users';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.get<any>(url, {headers: headers});
  }

  addUser(body: any): Observable<any> {
    const url = environment.URL_API + 'auth/register';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.post<any>(url, body, {headers: headers});
  }

  requestVerifyToken(email: string): Observable<any> {
    const url = environment.URL_API + 'auth/request-verify-token';
    return this.http.post<any>(url, {email: email});
  }

  verifyUser(token: any): Observable<any> {
    const url = environment.URL_API + 'auth/verify';
    return this.http.post<any>(url, {token: token});
  }

  editUser(user: any): Observable<any> {
    let body = new URLSearchParams();
    body.set('email', user.email);
    body.set('name', user.name);
    body.set('surname', user.surname);
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', `Bearer ${this.authenticationService.getToken()}`)
    };
    const url = environment.URL_API + 'users/update/' + user.id + `?role=${user.role}`;
    return this.http.put<any>(url, body.toString(), options);
  }

  getStatusList(): Observable<any> {
    const url = environment.URL_API + 'informes/estados';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.get<any>(url, {headers: headers});
  }

  getPlanningReport(body: any, formData: any): Observable<any> {
    const url = environment.URL_API + 'informes/reporte-planificacion?start_date=' + formData.startDateFilter + '&end_date=' + formData.endDateFilter + '&tipo_filtro=' + formData.typeFilter;
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.post<any>(url, body, {headers: headers});
  }

  getHoldertsList(): Observable<any> {
    const url = environment.URL_API + 'titulares';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.get<any>(url, {headers: headers});
  }

  getConsignments(body: any): Observable<any> {
    const url = environment.URL_API + 'remesas';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.post<any>(url, body, {headers: headers});
  }

  getMonthlyReport(body: any): Observable<any> {
    const url = environment.URL_API + 'informes/reporte-mensual';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.post<any>(url, body, {headers: headers});
  }

  getPaymentsReport(body: any): Observable<any> {
    const url = environment.URL_API + 'informes/reporte-pagos';
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authenticationService.getToken()}`);
    return this.http.post<any>(url, body, {headers: headers});
  }

}
