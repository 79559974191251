import { NotificationItem } from "../models/notification.model";
import { ProfileOptionItem } from "../models/profileoption.model";

const NOTIFICATIONS: NotificationItem[] = [
    {
        id: 1,
        isActive: true,
        text: 'Solange',
        subText: 'Relación recibida correctamente',
        avatar: 'assets/images/users/default-user.png',
    },
];

const PROFILEOPTIONS: ProfileOptionItem[] = [
    {
        label: 'Mi perfil',
        icon: 'fe-user',
        redirectTo: '/profile',
    },
    {
        label: 'Salir',
        icon: 'fe-log-out',
        redirectTo: '/auth/logout',
    }
];

export { NOTIFICATIONS, PROFILEOPTIONS };
