import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { EventService } from 'src/app/core/service/event.service';
import { EventType } from 'src/app/core/constants/events';
import { User } from 'src/app/core/models/auth.models';
import { NotificationItem } from '../models/notification.model';
import { ProfileOptionItem } from '../models/profileoption.model';
import { SearchResultItem } from '../models/search.model';
import { NOTIFICATIONS, PROFILEOPTIONS } from './data';
import { PageTitle } from '../models/page-title.model';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  notificationList: NotificationItem[] = [];
  profileOptions: ProfileOptionItem[] = [];
  searchResults: SearchResultItem[] = [];
  pageTitle: string = '';
  loggedInUser: User | null = null;
  topnavCollapsed: boolean = false;
  userToken = '';

  @Input() layoutType: string = 'vertical';
  @Input() containerClass?: string = '';

  // output events
  @Output() mobileMenuButtonClicked = new EventEmitter<void>();
  state = '';
  currentUser: any;
  showToastWithTourOnMessage = false;
  tourMessage = '';

  constructor (
    private authService: AuthenticationService,
    private eventService: EventService,
    private http: HttpClient) {
    this.eventService.on(EventType.CHANGE_PAGE_TITLE).subscribe(({ payload }) => {
      this.pageTitle = (payload as PageTitle).title;
    });
  }

  ngOnInit(): void {
    this._fetchSearchData();
    this._fetchNotifications();
    this._fetchProfileOptions();
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    if (this.currentUser !== null) {
      this.userToken = this.currentUser.token;
    }
  }

  _fetchNotifications(): void {
    this.notificationList = NOTIFICATIONS;
  }

  _fetchProfileOptions(): void {
    this.profileOptions = PROFILEOPTIONS;
  }

  _fetchSearchData(): void {
    this.searchResults = [{
      id: 1,
      text: 'Analytics Report',
      icon: 'fe-home',
    },
    {
      id: 2,
      text: 'How can I help you?',
      icon: 'fe-aperture',
    },
    {
      id: 3,
      text: 'User profile settings',
      icon: 'fe-settings',
    }];

  }

  toggleMobileMenu(event: any) {

    this.topnavCollapsed = !this.topnavCollapsed;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

}
